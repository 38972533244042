import React from 'react'
import get from 'lodash/get'
import RichText from '../RichText/RichText'
import "./ArticleDetail.scss"

class ArticleDetail extends React.Component {
    render() {
      const { title, content, createdAt } = get(this, 'props.article')
      const createdAtDate = createdAt ? new Date(createdAt) : null
      return (
        <article className="article-detail">
        {/* <pre>{JSON.stringify(this.props.article)}</pre> */}
        <header>
            <h2 className="title">{title}</h2>
            <ul className="tags">
            <li>
                <i className="fas fa-clock"></i>
                <span>
                {createdAtDate ? new Intl.DateTimeFormat("de-DE", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit"
                }).format(createdAtDate) : null}
                </span>
            </li>
            <li>
                <i className="fas fa-star"></i>
                <a href="/">Profile</a>
            </li>
            <li>
                <i className="fas fa-tags"></i>
                <a href="/">#Tag1</a>
                <a href="/">#Tag2</a>
            </li>
            </ul>
        </header>
        <section className="content">
            <RichText content={content.json}></RichText>
        </section>
        </article>
      )
    }
  }

export default ArticleDetail;
