import React from 'react'
import Layout from '../components/Layout/Layout'
import ArticleDetail from '../components/ArticleDetail/ArticleDetail';

class BlogPostTemplate extends React.Component {

  render() {
    const post = this.props.pageContext.post.node;

    return (
      <Layout location={this.props.location}>
      <div className="wrapper">
        <section className="article-list">
          <div className="container">
              <ArticleDetail article={post} />
          </div>
        </section>
      </div>
    </Layout>
    )
  }
}

export default BlogPostTemplate

